import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";

import { useDeleteSchema, useGetSchemaByIdOrKey } from "@packages/service-api";
import { schemasGridRef } from "../pages/schemas/SchemasList/SchemasGrid";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const DeleteSchema = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { idOrKey } = useParams<{ idOrKey: string }>();
  const { schema, loading: isLoading } = useGetSchemaByIdOrKey(idOrKey);
  const { deleteSchema, loading } = useDeleteSchema();

  const handleSave = async () => {
    try {
      await deleteSchema(idOrKey);
      schemasGridRef?.current?.api?.refreshServerSide();
      enqueueSnackbar(`Schema deleted successfully.`, {
        variant: "success"
      });
      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas`);
  };

  return (
    <Drawer
      title="Delete Schema"
      onClose={onClose}
      loading={isLoading || loading}
      disableBackdropClick
      actions={[
        { text: "Delete", action: handleSave, disabled: loading || isLoading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading || isLoading}>
        {schema?.schemaName && (
          <div>
            Are you sure you want to delete the schema <b>{`${schema?.schemaName}`}</b>?
          </div>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default DeleteSchema;
