import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { LayoutGroup, LayoutItem, ListOfValuesField } from "@packages/service-api";
import { ApplicationIcon } from "@packages/theme-mui-v5";

import FieldSelect from "./FieldSelect";
import SchemaLayoutRowField, { SchemaLayoutRowFieldProps } from "./SchemaLayoutRowField";
import { DragAndDrop } from "@packages/drag-and-drop";
import GroupName from "./GroupName";

export type SchemaLayoutFormRowProps = {
  fields: ListOfValuesField[];
  index: number;
  item: LayoutItem;
  onAddField: (rowIndex: number, field: ListOfValuesField) => void;
  onDeleteField: (rowIndex: number, layout) => void;
  onLayoutSort: (rowIndex: number, layout) => void;
  onUpdateGroup: (rowIndex: number, field: keyof LayoutGroup, value: string) => void;
};

const SchemaLayoutFormRow: React.FC<SchemaLayoutFormRowProps> = (props) => {
  const {
    fields,
    index,
    item: layoutItem,
    onAddField,
    onDeleteField,
    onLayoutSort,
    onUpdateGroup
  } = props;

  const [addFieldVisible, setAddFieldVisible] = React.useState<boolean>(
    layoutItem.type === "group" && layoutItem.layout.length === 0
  );

  const [addFieldSelected, setAddFieldSelected] = React.useState<ListOfValuesField>();

  const handleAddFieldToRow = () => {
    onAddField(index, addFieldSelected);

    setAddFieldSelected(undefined);
    setAddFieldVisible(false);
  };

  const handleItemsResort = async (items: LayoutItem[]) => {
    onLayoutSort(index, items);
  };

  const handleUpdateGroup = async (key: keyof LayoutGroup, value: string) => {
    onUpdateGroup(index, key, value);
  };

  const handleDeleteField = (layoutId: string) => {
    onDeleteField(index, layoutId);
  };

  return (
    <Box
      key={layoutItem.layoutId}
      sx={{
        border: layoutItem.type === "group" ? "1px dashed" : undefined,
        borderColor: layoutItem.type === "group" ? "grey.400" : undefined,
        position: "relative",
        pt: 2,
        width: "100%"
      }}
    >
      {layoutItem.type === "group" && (
        <GroupName group={layoutItem} onUpdateGroup={handleUpdateGroup} />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection:
            layoutItem.type === "group"
              ? layoutItem?.direction === "horizontal"
                ? "row"
                : "column"
              : undefined,
          my: 2
        }}
      >
        {layoutItem.type === "group" && (
          <DragAndDrop<LayoutItem, SchemaLayoutRowFieldProps>
            direction={layoutItem.direction || "horizontal"}
            itemKey="layoutId"
            items={layoutItem.layout}
            onSortChanged={handleItemsResort}
            listItemComponentProps={{
              fields,
              onDeleteField: handleDeleteField
            }}
            listItemComponent={SchemaLayoutRowField}
          />
        )}

        {addFieldVisible ? (
          <Box sx={{ display: "flex", flexDirection: "row", ml: 2 }}>
            <FieldSelect
              fields={fields}
              selected={addFieldSelected}
              onChange={(selectedField) => {
                setAddFieldSelected(selectedField);
              }}
            />

            {addFieldSelected && (
              <>
                <Tooltip title="Cancel">
                  <IconButton
                    disableRipple
                    onClick={() => {
                      setAddFieldSelected(undefined);
                      setAddFieldVisible(false);
                    }}
                    sx={{
                      flexGrow: "unset",
                      ml: 1,
                      mr: 0,
                      "&:hover": { backgroundColor: "transparent", color: "error.main" }
                    }}
                  >
                    <ApplicationIcon name="cancel" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirm">
                  <IconButton
                    disableRipple
                    onClick={() => handleAddFieldToRow()}
                    sx={{
                      flexGrow: "unset",
                      "&:hover": { backgroundColor: "transparent", color: "primary.main" }
                    }}
                  >
                    <ApplicationIcon name="check" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        ) : (
          <Tooltip title="Add Field">
            <Box
              sx={{
                alignItems: "center",
                border: "1px dashed",
                borderColor: "grey.400",
                borderRadius: 4,
                display: "flex",
                height: 40,
                justifyContent: "center",
                mb: 2.3,
                ml: 2,
                mt: 1,
                width: 100
              }}
            >
              <IconButton
                disableRipple
                onClick={() => setAddFieldVisible(true)}
                sx={{
                  flexGrow: "unset",
                  "&:hover": { backgroundColor: "transparent", color: "primary.main" }
                }}
              >
                <ApplicationIcon name="plus-circle" />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default SchemaLayoutFormRow;
