import { useMutation } from "react-query";
import { ListOfValuesApi } from "../../..";

export const useDeleteSchema = () => {
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation((idOrKey: string) =>
    ListOfValuesApi.Schemas.delete(idOrKey)
  );

  return {
    loading: isLoading,
    isError,
    isSuccess,
    deleteSchema: mutateAsync
  };
};
