import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";

import { useDeleteSchemaItem } from "@packages/service-api";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const DeleteItem = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { itemId, idOrKey } = useParams<{ itemId: string; idOrKey: string }>();
  const { deleteItem, loading } = useDeleteSchemaItem(idOrKey, itemId);

  const handleSave = async () => {
    try {
      await deleteItem();
      enqueueSnackbar(`Item deleted successfully.`, {
        variant: "success"
      });
      onClose();
    } catch (error) {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas/${idOrKey}/items`);
  };

  return (
    <Drawer
      title="Delete Item"
      onClose={onClose}
      loading={loading}
      disableBackdropClick
      actions={[
        { text: "Delete", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading}>
        <div>Are you sure you want to delete this item?</div>
      </WithLoader>
    </Drawer>
  );
};

export default DeleteItem;
