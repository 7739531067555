import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import PermissionForm, {
  ListOfValuesSchemaPermissionForm,
  PermissionFormRef
} from "../components/schemas/PermissionForm";
import {
  useGetSchemaByIdOrKey,
  useUpdateSchemaPermissions,
  ListOfValuesPermissionType
} from "@packages/service-api";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const AddPermission = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { actionType, idOrKey } = useParams<{
    actionType: ListOfValuesPermissionType;
    idOrKey: string;
  }>();
  const { updatePermission, loading } = useUpdateSchemaPermissions(actionType);

  const ref = React.useRef<PermissionFormRef>(null);
  const { schema, loading: loadingSchema } = useGetSchemaByIdOrKey(idOrKey);

  const schemaPermissions: ListOfValuesSchemaPermissionForm[] = React.useMemo(() => {
    return schema?.permissions[actionType];
  }, [actionType, schema]);

  const handleSubmit = async ({
    location,
    applicationId,
    permissionTypeId
  }: ListOfValuesSchemaPermissionForm) => {
    try {
      const updates = [
        ...schemaPermissions,
        { locationId: location.locationId, applicationId, permissionTypeId }
      ];
      await updatePermission({ idOrKey, previousPermissions: schemaPermissions, updates });
      enqueueSnackbar("Permission has been updated successfully.", {
        variant: "success"
      });
      onClose();
    } catch (error) {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas/${idOrKey}/permissions`);
  };

  const onCreate = () => {
    ref.current && ref.current.onSubmit();
  };

  const title = React.useMemo(() => {
    return `Add ${actionType.charAt(0).toUpperCase() + actionType.slice(1)} Permission`;
  }, [actionType]);

  return (
    <Drawer
      title={title}
      contentTitle="Permission"
      onClose={onClose}
      loading={loading || loadingSchema}
      actions={[
        {
          text: "Create",
          action: onCreate,
          disabled: loading
        },
        { text: "Cancel", action: onClose }
      ]}
    >
      <PermissionForm schemaPermissions={schemaPermissions} onSubmit={handleSubmit} ref={ref} />
    </Drawer>
  );
};

export default AddPermission;
