import { useMutation, useQueryClient } from "react-query";

import { CreateListOfValuesFieldBody, ListOfValuesSchemaDocumentResponse } from "../../../types";
import { ListOfValuesApi } from "../../..";
import { schemasSingleQueryKey } from "../query-cache";

type Variables = {
  idOrKey: string;
  field: CreateListOfValuesFieldBody;
};

export const useCreateSchemaField = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    ({ idOrKey, field }: Variables) => ListOfValuesApi.Schemas.createSchemaField(idOrKey, field),
    {
      onSuccess: (schema, variables) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(variables.idOrKey),
          () => schema
        );
      }
    }
  );

  return {
    schema: data,
    createSchemaField: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
