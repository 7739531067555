import React from "react";

import {
  LayoutField,
  LayoutGroup,
  LayoutItem,
  ListOfValuesField,
  ListOfValuesSchema,
  useUpdateSchemaFormLayout
} from "@packages/service-api";

import { getUuid } from "@packages/utils";
import SchemaLayoutFormRow, { SchemaLayoutFormRowProps } from "./SchemaLayoutFormRow";

import { DragAndDrop } from "@packages/drag-and-drop";
import { Box, Button } from "@mui/material";
import { WithLoader } from "@packages/theme-mui-v5";
type Props = {
  configure: boolean;
  schema: ListOfValuesSchema;
};

const SchemaLayoutForm: React.FC<Props> = (props) => {
  const { schema } = props;

  const [formLayout, setFormLayout] = React.useState(schema.layout?.form || []);
  const { updateFormLayout, loading: saving } = useUpdateSchemaFormLayout(schema.schemaId);

  const handleAddRow = (index: number = 0) => {
    const newRow: LayoutGroup = {
      direction: "horizontal",
      displayOrder: index || 0,
      layout: [],
      layoutId: getUuid(),
      name: `Group ${index + 1}`,
      type: "group"
    };

    setFormLayout((l) => [...l, newRow]);
  };

  const handleItemsResort = async (items: LayoutItem[]) => {
    setFormLayout(items);
    await updateFormLayout(items);
  };

  const handleAddFieldToRow = async (rowIndex: number, field: ListOfValuesField) => {
    const layoutField: LayoutField = {
      displayOrder: (formLayout[rowIndex] as LayoutGroup).layout.length,
      layoutId: getUuid(),
      key: field.fieldKey,
      type: "field"
    };

    const nextLayout = [...formLayout];
    (nextLayout[rowIndex] as LayoutGroup).layout.push(layoutField);

    setFormLayout(nextLayout);

    await updateFormLayout(nextLayout);
  };

  const handleRowItemsSort = async (rowIndex: number, layout: LayoutItem[]) => {
    const nextLayout = Array.from(formLayout);

    const nextLayoutGroup: LayoutGroup = {
      ...(nextLayout[rowIndex] as LayoutGroup),
      layout: layout
    };

    nextLayout[rowIndex] = nextLayoutGroup;

    setFormLayout(nextLayout);
    await updateFormLayout(nextLayout);
  };

  const handleGroupUpdate = async (rowIndex: number, key: keyof LayoutGroup, value: string) => {
    const nextLayout = Array.from(formLayout);

    const nextLayoutGroup: LayoutGroup = {
      ...(nextLayout[rowIndex] as LayoutGroup),
      [key]: value
    };

    nextLayout[rowIndex] = nextLayoutGroup;

    setFormLayout(nextLayout);
    await updateFormLayout(nextLayout);
  };

  const handleDeleteField = async (rowIndex: number, layoutId: string) => {
    const nextLayout = Array.from(formLayout);

    const nextLayoutGroup: LayoutGroup = {
      ...(nextLayout[rowIndex] as LayoutGroup),
      layout: (nextLayout[rowIndex] as LayoutGroup).layout.filter(
        (item) => item.layoutId !== layoutId
      )
    };

    nextLayout[rowIndex] = nextLayoutGroup;

    setFormLayout(nextLayout);
    await updateFormLayout(nextLayout);
  };

  return (
    <Box sx={{ m: 2 }}>
      <WithLoader loading={saving}>
        <DragAndDrop<LayoutItem, SchemaLayoutFormRowProps>
          onSortChanged={handleItemsResort}
          fullWidth
          items={formLayout}
          itemKey="layoutId"
          listItemComponentProps={{
            fields: schema.fields,
            onAddField: (rowIndex, field) => handleAddFieldToRow(rowIndex, field),
            onLayoutSort: handleRowItemsSort,
            onUpdateGroup: handleGroupUpdate,
            onDeleteField: handleDeleteField
          }}
          listItemComponent={SchemaLayoutFormRow}
        />
        <Box>
          <Button onClick={() => handleAddRow(formLayout.length)}> Add Row</Button>
        </Box>
      </WithLoader>
    </Box>
  );
};

export default SchemaLayoutForm;
