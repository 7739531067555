import { ApiListResponse, ApiSingleDocumentResponse } from "../../../api-definitions";
import { ListOfValuesField } from "./fields";
import { ListOfValuesSchemaLayout } from "./layout";
import { ListOfValuesSchemaPermissions } from "./permissions";

type SchemaSetting = {
  key: string;
  value: string | number;
};

export type SchemaType = "manual-entry" | "structured-data";

export type ListOfValuesSchema = {
  schemaId: string;
  schemaType: SchemaType;
  schemaName: string;
  schemaKey: string;
  applicationId: string | null;
  locationId: string | null;
  tags: string[];
  permissions: ListOfValuesSchemaPermissions;
  layout: ListOfValuesSchemaLayout;
  fields: ListOfValuesField[];
  settings: SchemaSetting[];
};

export type ListOfValuesSchemaItem = {
  pk: string;
  sk: string;
  itemId: string;
  schemaId: string;
  timeStamp: string; // TODO iso string type?
  attributes: {
    [key: string]: number | string | string[]; // TODO any more field types?
  };
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  recordType: string;
};

export type ListOfValuesSchemaDocumentResponse = ApiSingleDocumentResponse<ListOfValuesSchema>;
export type ListOfValuesSchemaListResponse = ApiListResponse<ListOfValuesSchema>;
export type ListOfValuesSchemaItemsListResponse = ApiListResponse<ListOfValuesSchemaItem>;
export type CreateListOfValuesSchemaBody = Pick<
  ListOfValuesSchema,
  "applicationId" | "locationId" | "schemaKey" | "schemaName" | "schemaType" | "tags" | "settings"
>;

export const listOfValuesSchemaTypes: Record<SchemaType, string> = {
  "manual-entry": "Manual Entry",
  "structured-data": "Structured Data"
};
