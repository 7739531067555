import * as React from "react";
import { ListOfValuesField } from "@packages/service-api";
import { ColDef, DataGrid, ICellRendererParams, RowActionsCell } from "@packages/data-grid";
import { useInternalNavigate } from "../../../../../components/InternalNavigationProvider";

type Props = {
  fields: ListOfValuesField[];
  schemaId: string;
  configure: boolean;
};

const SchemaFieldsGrid: React.FC<Props> = (props) => {
  const { fields, schemaId, configure } = props;
  const navigate = useInternalNavigate();

  const RenderActionsCell = (params: ICellRendererParams) => {
    return (
      <RowActionsCell
        actions={[
          {
            icon: "copy-plus",
            color: "primary",
            tooltip: "Clone",
            action: () => navigate(`/schemas/${schemaId}/fields/${params.data.fieldKey}/clone`)
          },
          {
            icon: "edit",
            color: "primary",
            tooltip: "Edit Field",
            action: () => navigate(`/schemas/${schemaId}/fields/${params.data.fieldKey}/edit`)
          },
          {
            icon: "delete",
            color: "error",
            tooltip: "Delete Field",
            action: () => navigate(`/schemas/${schemaId}/fields/${params.data.fieldKey}/delete`)
          }
        ]}
      />
    );
  };

  const columnDefs = React.useMemo<ColDef[]>(() => {
    return [
      { field: "fieldKey", colId: "fieldKey", headerName: "Key" },
      { field: "label.0.value", colId: "label", headerName: "Label" },
      { field: "type", colId: "type", headerName: "Type" },
      {
        field: "isRequired",
        colId: "required",
        headerName: "Required",
        maxWidth: 110,
        cellRenderer: "BooleanRenderer"
      },
      ...(configure
        ? [
            {
              field: "actions",
              headerName: "",
              maxWidth: 120,
              minWidth: 120,
              cellRenderer: RenderActionsCell
            }
          ]
        : [])
    ];
  }, [configure]);

  return (
    <DataGrid<ListOfValuesField>
      rowData={fields}
      columnDefs={columnDefs}
      overlayNoRowsTemplate="This schema has no fields"
    />
  );
};

export default SchemaFieldsGrid;
