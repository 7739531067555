import { UseQueryOptions, useQuery } from "react-query";
import { LocationListResponse, Location, QueryOptions, HTTPError } from "../../../types";
import { CommonDataApi } from "../../..";
import { locationsOfApplications } from "./query-cache";

/**
 * Get a list of locations for a specific application.
 */
export const useGetLocationsByApplicationIds = (applicationIds: string[], query?: QueryOptions) => {
  const queryOptions: UseQueryOptions<LocationListResponse[], HTTPError, Location[]> = {
    queryKey: locationsOfApplications(applicationIds),
    queryFn: () =>
      Promise.all(
        applicationIds.map((applicationId) => {
          return CommonDataApi.Locations.getByApplicationId(applicationId, query);
        })
      ),
    select: (data) => {
      const result: Location[] = [];
      if (data?.length) {
        data.forEach((item) => {
          result.push(...item.data);
        });
      }
      return result;
    },
    enabled: !!applicationIds?.length
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    allLocations: data ? data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
