import { FC } from "react";
import { PageContainer } from "@packages/theme-mui-v5";
import { Application } from "@nutrien-operations/config";

import SchemaAdminRoutes from "../../SchemaAdminRoutes";
import InternalNavigationProvider from "../../components/InternalNavigationProvider";

type Props = {
  appConfig?: Application;
  basePath?: string;
};

export const ListOfValuesAdmin: FC<Props> = (props) => {
  const { appConfig, basePath } = props;
  return (
    <PageContainer appConfig={appConfig} hideLocationbar>
      <InternalNavigationProvider basePath={basePath || appConfig.path?.value}>
        <SchemaAdminRoutes appConfig={appConfig} />
      </InternalNavigationProvider>
    </PageContainer>
  );
};
