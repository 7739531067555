import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesApi } from "../../..";
import { ListOfValuesField, ListOfValuesSchemaDocumentResponse } from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

type Variables = {
  idOrKey: string;
  previousField: ListOfValuesField;
  updates: Partial<ListOfValuesField>;
};

export const useUpdateSchemaField = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ idOrKey, previousField, updates }: Variables) =>
      ListOfValuesApi.Schemas.updateSchemaField(
        idOrKey,
        previousField.fieldKey,
        previousField,
        updates
      ),
    {
      onSuccess: (schema, variables) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(variables.idOrKey),
          () => schema
        );
      }
    }
  );

  return {
    loading: isLoading,
    isError,
    isSuccess,
    updateField: mutateAsync
  };
};
