import React from "react";
import {
  ListOfValuesRenderType,
  useGetSchemaByIdOrKey,
  useGetSchemaPermissions
} from "@packages/service-api";
import SchemaLayoutGrid from "./grid/SchemaLayoutGrid";
import { Box, Button, ButtonGroup } from "@mui/material";
import { WithLoader } from "@packages/theme-mui-v5";
import SchemaLayoutForm from "./form/SchemaLayoutForm";

type Props = {
  schemaIdOrKey: string;
};

const SchemaLayoutTab: React.FC<Props> = (props) => {
  const { schemaIdOrKey } = props;
  const { schema, loading } = useGetSchemaByIdOrKey(schemaIdOrKey);
  const { configure } = useGetSchemaPermissions(schema);

  const [layoutType, setLayoutType] = React.useState<ListOfValuesRenderType>("form");

  const renderButton = (type: ListOfValuesRenderType) => {
    return (
      <Button
        onClick={() => setLayoutType(type)}
        sx={{ textTransform: "capitalize" }}
        variant={layoutType === type ? "contained" : undefined}
      >
        {type}
      </Button>
    );
  };
  return (
    <Box sx={{ minHeight: 300 }}>
      <WithLoader loading={loading}>
        {schema && (
          <>
            <ButtonGroup disableElevation sx={{ mb: 2, mx: 2 }}>
              {renderButton("form")}
              {renderButton("grid")}
            </ButtonGroup>

            {layoutType === "form" && <SchemaLayoutForm configure={configure} schema={schema} />}
            {layoutType === "grid" && <SchemaLayoutGrid configure={configure} schema={schema} />}
          </>
        )}
      </WithLoader>
    </Box>
  );
};

export default SchemaLayoutTab;
