import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import {
  ListOfValuesPermissionType,
  ListOfValuesSchemaPermission,
  useGetSchemaByIdOrKey,
  useUpdateSchemaPermissions,
  useGetPermissionById
} from "@packages/service-api";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const DeletePermission = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { actionType, idOrKey, permissionTypeId } = useParams<{
    actionType: ListOfValuesPermissionType;
    idOrKey: string;
    permissionTypeId: string;
  }>();

  const { permission, loading: loadingPermission } = useGetPermissionById(permissionTypeId);
  const { updatePermission, loading } = useUpdateSchemaPermissions(actionType);

  const { schema, loading: loadingSchema } = useGetSchemaByIdOrKey(idOrKey);

  const schemaPermissions: ListOfValuesSchemaPermission[] = React.useMemo(() => {
    return schema?.permissions[actionType];
  }, [schema]);

  const handleSubmit = async () => {
    const updates = schemaPermissions.filter((data) => data.permissionTypeId !== permissionTypeId);
    try {
      await updatePermission({ idOrKey, previousPermissions: schemaPermissions, updates });
      enqueueSnackbar("Permission has been deleted successfully.", {
        variant: "success"
      });
      onClose();
    } catch (error) {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas/${idOrKey}/permissions`);
  };

  return (
    <Drawer
      title="Delete Permission"
      onClose={onClose}
      loading={loadingSchema || loading || loadingPermission}
      disableBackdropClick
      actions={[
        { text: "Delete", action: handleSubmit, disabled: loading || loadingSchema },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loadingSchema || loading || loadingPermission}>
        Are you sure you want to delete the permission <b>{`${permission?.name || "this"}`}</b>?
      </WithLoader>
    </Drawer>
  );
};

export default DeletePermission;
