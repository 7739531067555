import { Application } from "@nutrien-operations/config";

export const basePath = "/list-of-values";

const permissions = {
  configureApplication: "d5dade54-e0b1-45cb-8bc6-4530ab574e51"
};

const applicationConfig: Application = {
  applicationId: "d3be0fa2-a58b-448e-b6a5-f32e6025c067",
  name: "@nutrien-operations/list-of-values-admin",
  siteAgnostic: true,
  displayName: "List of Values",
  icon: "list-plus",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "List of Values",
    path: "/",
    icon: "list-plus",
    permission: permissions.configureApplication,
    children: [
      {
        label: "Schemas",
        path: "/lov/schemas",
        permission: permissions.configureApplication
      },
      {
        label: "Admin",
        path: "/admin",
        permission: permissions.configureApplication
      }
    ]
  },
  swagger: {
    localdev: { url: "https://dev-lov-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-lov-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-lov-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-lov-swagger-ui.np.nutrien.com"
    }
  }
};

export default applicationConfig;
