import * as React from "react";
import { Box } from "@mui/material";
import { WithLoader, Dialog } from "@packages/theme-mui-v5";
import { useParams } from "react-router-dom";
import { useGetSchemaByIdOrKey, useUpdateSchemaGridLayout } from "@packages/service-api";
import { findFieldByPath } from "@packages/utils";
import { useInternalNavigate } from "../../../../../../../components/InternalNavigationProvider";

const DeleteLayoutItemModal: React.FC = () => {
  const [showDialogConfirmation, setShowDialogConfirmation] = React.useState<boolean>(true);

  const { idOrKey, itemPathKey } = useParams<{ idOrKey: string; itemPathKey: string }>();
  const { schema } = useGetSchemaByIdOrKey(idOrKey);

  const navigate = useInternalNavigate();

  const layoutItem = React.useMemo(() => {
    return findFieldByPath(itemPathKey, schema?.layout?.grid);
  }, [schema, itemPathKey]);

  const { updateGridLayout, loading: loadingSchemaGridLayout } = useUpdateSchemaGridLayout(idOrKey);

  const handleClose = () => {
    setShowDialogConfirmation(false);
    navigate(`/schemas/${idOrKey}/layout`);
  };

  const handleDeleteLayoutItem = async () => {
    await updateGridLayout({
      itemPathKey,
      action: "remove",
      value: layoutItem
    });
    handleClose();
  };

  return (
    <Box sx={{ minHeight: 300 }}>
      <WithLoader loading={loadingSchemaGridLayout}>
        <Dialog
          maxWidth="sm"
          open={showDialogConfirmation}
          title="Confirm Delete"
          onClose={handleClose}
          actions={[
            {
              text: "Cancel",
              action: handleClose,
              variant: "outlined"
            },
            {
              text: "Delete item",
              action: handleDeleteLayoutItem,
              variant: "contained"
            }
          ]}
        >
          <Box>Are you sure you want to delete this item from the layout</Box>
        </Dialog>
      </WithLoader>
    </Box>
  );
};

export default DeleteLayoutItemModal;
