import React from "react";
import { ListOfValuesField } from "@packages/service-api";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";

type Props = {
  error?: string;
  fields: ListOfValuesField[];
  loading?: boolean;
  onChange: (field: ListOfValuesField | undefined) => void;
  selected?: ListOfValuesField;
};

const FieldSelect: React.FC<Props> = (props) => {
  const { error, fields, loading, onChange, selected } = props;

  return (
    <FormControl className="mui-select" sx={{ minWidth: 300 }} variant="standard">
      <InputLabel
        error={!!error}
        required
        shrink={true}
        sx={{ fontWeight: 600, color: error ? "#F24965" : "#333333" }}
      >
        Field
        {loading && <CircularProgress sx={{ ml: 1 }} size={16} />}
      </InputLabel>

      <Select
        label="Field"
        onChange={(e) => {
          const nextField = fields.find((f) => f.fieldKey === e.target.value);
          onChange(nextField);
        }}
        value={selected?.fieldKey || ""}
      >
        <MenuItem value="">- </MenuItem>
        {fields.map((field, index) => (
          <MenuItem key={index} value={field.fieldKey}>
            {Array.isArray(field.label) ? field.label[0]?.value : field.fieldKey}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error={true}>{error}</FormHelperText>
    </FormControl>
  );
};

export default FieldSelect;
