import { useMutation, useQueryClient } from "react-query";

import { ListOfValuesItem, ListOfValuesSchema } from "../../../types";

import { schemaItemsQueryKey, singleSchemaItemQueryKey } from "../query-cache";

import { ListOfValuesApi, useGetSchemaItemById } from "../../..";

export const useUpdateSchemaItem = (idOrKey: string, itemId: string) => {
  const queryClient = useQueryClient();

  const { item: prevItem, loading: itemLoading } = useGetSchemaItemById(idOrKey, itemId);

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({
      nextAttributes,
      schema
    }: {
      nextAttributes: ListOfValuesItem["attributes"];
      schema: ListOfValuesSchema;
    }) => {
      return ListOfValuesApi.Items.update(idOrKey, prevItem, nextAttributes, schema);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: singleSchemaItemQueryKey(idOrKey, itemId) });
        queryClient.invalidateQueries({ queryKey: schemaItemsQueryKey(idOrKey) });
      }
    }
  );

  return {
    loading: isLoading || itemLoading,
    isError,
    isSuccess,
    updateItem: mutateAsync
  };
};
