import React from "react";
import {
  getSelectedLocationLabel,
  useGetApplications,
  useGetLocationsByApplicationIds,
  useGetPermissionsByApplicationIds,
  useGetSchemaByIdOrKey,
  useGetSchemaPermissions
} from "@packages/service-api";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { WithLoader } from "@packages/theme-mui-v5";
import { DataGrid, ColDef, ICellRendererParams, RowActionsCell } from "@packages/data-grid";
import { useInternalNavigate } from "../../../../../components/InternalNavigationProvider";

type Props = {
  schemaIdOrKey: string;
};

const listOfValuesPermission = ["configure", "read", "write"];

const SchemaPermissionsTab: React.FC<Props> = (props) => {
  const { schema, loading } = useGetSchemaByIdOrKey(props.schemaIdOrKey);

  const { configure } = useGetSchemaPermissions(schema);

  // Get all applications name with ids
  const { applications, loading: applicationsLoading } = useGetApplications();
  // Get applicationIds from schema
  const applicationIds: string[] = React.useMemo(() => {
    if (loading) {
      return [];
    }

    const applicationIds: string[] = [];

    if (schema?.permissions) {
      // loop over each permissions and get ids
      listOfValuesPermission.forEach((key) => {
        if (schema.permissions[key]?.length) {
          schema.permissions[key].forEach((item) => {
            applicationIds.push(item.applicationId);
          });
        }
      });
    }

    return [...new Set(applicationIds)]; // get only unique ids
  }, [schema, loading]);

  // Get all permissions name with ids
  const { allPermissions: permissionsList, loading: loadingPermission } =
    useGetPermissionsByApplicationIds(applicationIds);

  // Get all locations name with ids
  const { allLocations: locations, loading: loadingLocation } = useGetLocationsByApplicationIds(
    applicationIds,
    { size: 1000 }
  );

  const { idOrKey } = useParams<{ idOrKey: string }>();

  const navigate = useInternalNavigate();

  const columnDefs: ColDef[] = React.useMemo(() => {
    if (!configure) return [];
    return [
      {
        field: "permissionTypeId",
        headerName: "",
        maxWidth: 50,
        cellClass: "align-center",
        cellRenderer: (params: ICellRendererParams) => {
          if ([1, 2].includes(params.node.level)) return;
          const actions = [];
          if (params.node.level === 0) {
            actions.push({
              icon: "list-plus",
              color: "primary",
              tooltip: "Add Permission",
              action: () => navigate(`/schemas/${idOrKey}/permissions/${params.node.key}/new`)
            });
          } else {
            // don't show delete icon for application and location group
            actions.push({
              icon: "delete",
              color: "error",
              tooltip: "Delete Permission",
              action: () =>
                navigate(
                  `/schemas/${idOrKey}/permissions/${params.node.data.path[0]}/${params.data.permissionTypeId}/delete`
                )
            });
          }
          return <RowActionsCell actions={actions} />;
        }
      }
    ];
  }, [configure]);

  const autoGroupColumnDef = React.useMemo<ColDef>(() => {
    return {
      headerName: "Name",
      valueFormatter: (params) => {
        if (params.node.level === 0) {
          return params.value;
        } else if (params.node.level === 1) {
          return applications.find((data) => data.applicationId === params.value)?.applicationName;
        } else if (params.node.level === 2) {
          const location = locations.find((data) => data.locationId === params.value);
          const { selectedLocationTitle } = getSelectedLocationLabel(
            location.locationId,
            locations
          );
          return selectedLocationTitle;
        } else if (params.node.level === 3) {
          return permissionsList?.find((data) => data.permissionTypeId === params.value)?.name;
        } else {
          return params.value;
        }
      },
      cellClass: (params) =>
        params.node.level === 0 ? "primary-text text-transform-capitalize" : "",
      cellRendererParams: {
        suppressCount: true
      }
    };
  }, [locations, applications, permissionsList]);

  const schemaData = React.useMemo(() => {
    if (loading || loadingPermission || applicationsLoading || loadingLocation) return null;
    // ag grid data
    const rowData = [];
    if (schema?.permissions) {
      listOfValuesPermission.forEach((key) => {
        // if permissions[key] data exits add to rowData otherwise create new row to display in Ag-grid
        if (schema.permissions[key]?.length) {
          schema.permissions[key].forEach((item) => {
            rowData.push({
              ...item,
              path: [key, item.applicationId, item.locationId, item.permissionTypeId]
            });
          });
        } else {
          // create new row to display in Ag-grid
          rowData.push({
            permissionTypeId: null,
            applicationId: null,
            locationId: null,
            path: [key]
          });
        }
      });
    }
    return rowData;
  }, [
    schema,
    permissionsList,
    applications,
    loading,
    loadingPermission,
    applicationsLoading,
    locations,
    loadingLocation
  ]);

  const getDataPath = React.useMemo(() => (data) => data.path, []);

  const isLoading = loading || applicationsLoading || loadingPermission || loadingLocation;

  return (
    <Box minHeight={400} position="relative">
      <WithLoader loading={isLoading}>
        {!isLoading && (
          <DataGrid
            loading={loading}
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={columnDefs}
            rowData={schemaData}
            getDataPath={getDataPath}
            treeData={true}
          />
        )}
      </WithLoader>
    </Box>
  );
};

export default SchemaPermissionsTab;
