import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesItem, ListOfValuesItemListResponse } from "../../../types";
import { ListOfValuesApi } from "../../..";
import { schemaItemsQueryKey } from "../query-cache";

export const useCreateSchemaItem = (idOrKey: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation({
    mutationFn: (itemAttributes: ListOfValuesItem["attributes"]) =>
      ListOfValuesApi.Items.create(idOrKey, itemAttributes),
    onSuccess: (item) => {
      queryClient.setQueryData<ListOfValuesItemListResponse | undefined>(
        schemaItemsQueryKey(idOrKey),
        (data) => {
          if (!data) {
            return {
              data: [item.data],
              hasMore: false,
              total: 1
            };
          }

          return {
            ...data,
            data: [...data.data, item.data]
          };
        }
      );
    }
  });

  return {
    schema: data,
    createItem: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
