import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ThemeProvider, AppContainer } from "@packages/theme-mui-v5";

import Router from "./Router";
import config from "./config";
import { ApiProvider } from "@packages/service-api";
import { initializeSentry } from "@packages/sentry";

initializeSentry(config.name);

export default function TemplateApp() {
  return (
    <ApiProvider>
      <AppContainer appConfig={config}>
        <ThemeProvider provider={MUIThemeProvider}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router />
          </LocalizationProvider>
        </ThemeProvider>
      </AppContainer>
    </ApiProvider>
  );
}
