import { FC, useEffect, useMemo, useState } from "react";
import SchemasGrid from "./SchemasGrid";
import { Outlet } from "react-router-dom";
import { ApplicationIcon, PageToolbar, WithLoader } from "@packages/theme-mui-v5";
import { Application, useGetApplications } from "@packages/service-api";
import ApplicationSelect from "../../../components/admin/ApplicationSelect";
import { useInternalNavigate } from "../../../components/InternalNavigationProvider";

type Props = {
  showApplicationSelection?: boolean;
};

export const SchemasList: FC<Props> = ({ showApplicationSelection }) => {
  const navigate = useInternalNavigate();
  const [selectedApplication, setSelectedApplication] = useState<Application>();
  const { applications, loading: loadingApplications } = useGetApplications();

  useEffect(() => {
    if (!showApplicationSelection || applications.length === 0) {
      return;
    }

    const searchStr = window.location.search.replace(/^\?/, "");
    const params = new URLSearchParams(searchStr);
    const applicationId = params.get("applicationId");

    if (applicationId) {
      if (applicationId === selectedApplication?.applicationId) {
        return;
      }

      const application = applications.find((app) => app.applicationId === applicationId);

      if (application && application?.applicationId !== selectedApplication?.applicationId) {
        setSelectedApplication(application);
      }
    }
  }, [applications]);

  const rightActions = useMemo(() => {
    const actions = [];

    if (showApplicationSelection) {
      actions.push({
        type: "component",
        element: (
          <ApplicationSelect
            selected={selectedApplication}
            onChange={(application: Application) => setSelectedApplication(application)}
          />
        )
      });
    }

    actions.push({
      type: "button",
      title: "Add Schema",
      variant: "contained",
      onClick: () => navigate("/schemas/new"),
      startIcon: <ApplicationIcon name="plus" />
    });

    return actions;
  }, [showApplicationSelection, selectedApplication]);

  return (
    <WithLoader loading={loadingApplications}>
      <PageToolbar title="Schemas" rightActions={rightActions} />
      <SchemasGrid applicationId={selectedApplication?.applicationId} />
      <Outlet />
    </WithLoader>
  );
};
