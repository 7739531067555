import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { Box } from "@mui/material";
import { ApplicationIcon } from "@packages/theme-mui-v5";

type Props = {
  children: React.ReactNode;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleProps?: any;
  fullWidth?: boolean;
  id: string;
};

const SortableListItem: React.FC<Props> = (props) => {
  const { children, fullWidth, id, handleProps } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      style={{
        position: "relative",
        transform: CSS.Transform.toString(transform),
        transition,
        width: fullWidth ? "100%" : "inherit"
      }}
      {...attributes}
    >
      <Box
        {...handleProps}
        {...listeners}
        style={{
          alignItems: "center",
          backgroundColor: "white",
          cursor: "grab",
          display: "flex",
          height: 16,
          justifyContent: "center",
          position: "absolute",
          right: 10,
          top: -8,
          width: 16,
          zIndex: 10
        }}
      >
        <ApplicationIcon name="move" size="small" sx={{ color: "primary.main", fontSize: 12 }} />
      </Box>

      {children}
    </div>
  );
};

export default SortableListItem;
