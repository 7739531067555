import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ListOfValuesAdmin } from "@packages/list-of-values";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route
          path="lov/*"
          element={<ListOfValuesAdmin basePath="/lov" appConfig={applicationConfig} />}
        />

        <Route path="*" element={<Navigate to="/lov" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
