import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesApi } from "../../..";
import { ListOfValuesSchemaDocumentResponse, ListOfValuesSchemaPermission } from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

type Variables = {
  idOrKey: string;
  previousPermissions: ListOfValuesSchemaPermission[];
  updates: Partial<ListOfValuesSchemaPermission[]>;
};

export const useUpdateSchemaPermissions = (actionType: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ idOrKey, previousPermissions, updates }: Variables) =>
      ListOfValuesApi.Schemas.updateSchemaPermissions(
        idOrKey,
        actionType,
        previousPermissions,
        updates
      ),
    {
      onSuccess: (_application, variables) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(variables.idOrKey),
          (currentState: ListOfValuesSchemaDocumentResponse | undefined) => {
            if (!currentState) return undefined;
            return {
              ...currentState,
              data: { ...currentState.data, permissions: _application.data.permissions }
            };
          }
        );
      }
    }
  );

  return {
    loading: isLoading,
    isError,
    isSuccess,
    updatePermission: mutateAsync
  };
};
