import { FormControl, MenuItem, Select } from "@mui/material";
import { Application, useGetApplications } from "@packages/service-api";
import { WithLoader } from "@packages/theme-mui-v5";
import { FC, useEffect, useMemo, useState } from "react";

type Props = {
  onChange?: (option: Application) => void;
  selected?: Application;
};

const ApplicationSelect: FC<Props> = (props) => {
  const { onChange, selected } = props;
  const { applications, loading } = useGetApplications();
  const [selectedId, setSelectedId] = useState<string>("all");

  useEffect(() => {
    // We don't want to do any checks if there are no applications available to search for the selectedId
    if (applications.length === 0) {
      return;
    }

    const application = applications.find((app) => app.applicationId === selectedId);

    const searchStr = window.location.search.replace(/^\?/, "");
    const params = new URLSearchParams(searchStr);
    const urlApplicationId = params.get("applicationId");

    if (selectedId === "all" && !!urlApplicationId) {
      return;
    }

    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}${
        application ? `?applicationId=${application.applicationId}` : ""
      }`
    );
  }, [applications, selectedId]);

  useEffect(() => {
    if (selected && selected?.applicationId !== selectedId) {
      setSelectedId(selected.applicationId);
    }
  }, [selected]);

  const applicationsOptions = useMemo(() => {
    if (applications?.length) {
      return applications.map((item) => ({
        value: item.applicationId,
        label: item.applicationName
      }));
    }
    return [];
  }, [applications]);

  const handleOnChange = (event) => {
    if (event.target.value !== selectedId) {
      setSelectedId(event.target.value);

      const application = applications.find((app) => app.applicationId === event.target.value);

      if (onChange) {
        onChange(application);
      }
    }
  };

  return (
    <WithLoader loading={loading}>
      <FormControl sx={{ minWidth: 200 }}>
        <Select
          value={selectedId}
          variant="standard"
          labelId="application-select-label"
          id="application-select"
          size="small"
          onChange={handleOnChange}
        >
          <MenuItem value="all">All Applications</MenuItem>
          {applicationsOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </WithLoader>
  );
};

export default ApplicationSelect;
