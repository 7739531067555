import React from "react";
import { UseQueryOptions, useQuery } from "react-query";
import {
  CommonDataApi,
  HTTPError,
  Permission,
  PermissionListResponse,
  QueryOptions
} from "../../..";
export const useGetPermissionsByApplicationIds = (
  applicationIds: string[],
  query?: QueryOptions
) => {
  const key = applicationIds?.join();
  const queryOptions: UseQueryOptions<PermissionListResponse[], HTTPError> = {
    queryKey: ["permissions", "by-application", key],
    queryFn: () =>
      Promise.all(
        applicationIds.map((applicationId) => {
          return CommonDataApi.Permissions.get(applicationId, query);
        })
      ),
    enabled: !!applicationIds?.length
  };
  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  const allPermissions = React.useMemo(() => {
    const result: Permission[] = [];
    if (data?.length) {
      data.forEach((item) => {
        result.push(...item.data);
      });
    }
    return result;
  }, [data]);

  const permissions = React.useMemo(() => {
    if (allPermissions?.length) {
      return allPermissions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    }
    return [];
  }, [allPermissions]);

  return {
    permissionsList: permissions,
    allPermissions,
    loading: isLoading,
    isError,
    isSuccess
  };
};
