import React, { FC } from "react";
import {
  ApplicationFilterType,
  ListOfValuesApi,
  ListOfValuesSchema,
  listOfValuesSchemaTypes
} from "@packages/service-api";

import {
  AgGridReactProps,
  CACHE_BLOCK_SIZE,
  ColDef,
  DataGrid,
  ICellRendererParams,
  IServerSideGetRowsParams,
  RowActionsCell
} from "@packages/data-grid";

import { useInternalNavigate } from "../../../components/InternalNavigationProvider";

export let schemasGridRef = React.createRef<AgGridReactProps<ListOfValuesSchema>>();

type Props = {
  applicationId?: string;
};

const SchemasGrid: FC<Props> = ({ applicationId }) => {
  const navigate = useInternalNavigate();

  const RenderActionsCell = (params: ICellRendererParams) => {
    return (
      <RowActionsCell
        actions={[
          {
            icon: "layout-list",
            color: "primary",
            tooltip: "Schema Details",
            action: () => navigate(`/schemas/${params.data.schemaId}`)
          },
          {
            icon: "edit",
            color: "primary",
            tooltip: "Edit Schema",
            action: () => navigate(`/schemas/${params.data.schemaId}/edit`)
          },
          {
            icon: "grid",
            color: "primary",
            tooltip: "View or Edit Items",
            action: () => navigate(`/schemas/${params.data.schemaId}/items`)
          },
          {
            icon: "delete",
            color: "error",
            tooltip: "Delete Schema",
            action: () => navigate(`/schemas/${params.data.schemaId}/delete`)
          }
        ]}
      />
    );
  };

  const columnDefs = React.useMemo<ColDef[]>(() => {
    return [
      { field: "schemaName", colId: "schemaName", headerName: "Name" },
      { field: "schemaKey", colId: "schemaKey", headerName: "Schema Key" },
      {
        field: "schemaType",
        colId: "schemaType",
        headerName: "Type",
        valueFormatter: ({ data }) => listOfValuesSchemaTypes[data.schemaType]
      },
      {
        field: "actions",
        headerName: "",
        maxWidth: 150,
        cellRenderer: RenderActionsCell
      }
    ];
  }, []);

  const dataSource = React.useMemo(() => {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const filters = {} as ApplicationFilterType;

        if (applicationId && applicationId !== "all") {
          filters.applicationId = applicationId;
        }

        Object.keys(params.request.filterModel).map((key) => {
          if (params.request.filterModel[key].values?.length) {
            filters[key] = params.request.filterModel[key].values;
          }
        });

        ListOfValuesApi.Schemas.get(filters as Partial<ListOfValuesSchema>, {
          page: params.request.startRow,
          size: CACHE_BLOCK_SIZE
        }).then((data) => {
          params.success({ rowData: data.data });
        });
      }
    };
  }, [applicationId]);

  React.useEffect(() => {
    return () => {
      schemasGridRef = null;
    };
  }, [dataSource, schemasGridRef]);

  return (
    <DataGrid<ListOfValuesSchema>
      gridRef={schemasGridRef}
      columnDefs={columnDefs}
      isServersidePagination
      serverSideDatasource={dataSource}
    />
  );
};

export default SchemasGrid;
