import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";

import { useDeleteSchemaField, useGetSchemaByIdOrKey } from "@packages/service-api";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const DeleteField = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { fieldKey, idOrKey } = useParams<{ fieldKey: string; idOrKey: string }>();
  const { schema, loading: isLoading } = useGetSchemaByIdOrKey(idOrKey);
  const { deleteField, loading } = useDeleteSchemaField();

  const handleSave = async () => {
    try {
      await deleteField({ fieldKey, idOrKey });
      enqueueSnackbar(`Field deleted successfully.`, {
        variant: "success"
      });
      onClose();
    } catch (error) {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas/${idOrKey}/fields`);
  };

  const fieldToDelete = React.useMemo(() => {
    return schema?.fields.find((f) => f.fieldKey === fieldKey);
  }, [schema]);

  return (
    <Drawer
      title="Delete Field"
      onClose={onClose}
      loading={isLoading || loading}
      disableBackdropClick
      actions={[
        { text: "Delete", action: handleSave, disabled: loading || isLoading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={isLoading || loading}>
        {schema?.schemaName && (
          <div>
            Are you sure you want to delete the field <b>{`${fieldToDelete?.fieldKey}`}</b>?
          </div>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default DeleteField;
