import * as React from "react";
import { useParams } from "react-router-dom";
import { ApplicationIcon, Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import { SchemaFormRef } from "../components/schemas/SchemaForm";

import {
  ListOfValuesField,
  ListOfValuesFieldType,
  useCreateSchemaField,
  useGetSchemaByIdOrKey,
  useUpdateSchemaField
} from "@packages/service-api";
import { Box, Typography } from "@mui/material";
import SchemaFieldForm from "../components/schemas/SchemaFieldForm";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

type Props = {
  isClone?: boolean;
};

const AddEditField = (props: Props) => {
  const { isClone } = props;
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [fieldType, setFieldType] = React.useState<ListOfValuesFieldType>();

  const { fieldKey, idOrKey } = useParams<{ fieldKey: string; idOrKey: string }>();

  const { updateField, loading: updatingField } = useUpdateSchemaField();
  const { createSchemaField, loading: creatingField } = useCreateSchemaField();
  const { schema, loading: loadingSchema } = useGetSchemaByIdOrKey(idOrKey);

  const schemaField = React.useMemo(() => {
    const field = schema?.fields.find((f) => f.fieldKey === fieldKey);
    return isClone
      ? {
          ...field,
          fieldKey: `${field.fieldKey} (copy)`,
          label: [{ locale: field.label[0].locale, value: `${field.label[0].value} (copy)` }]
        }
      : field;
  }, [schema, fieldKey, isClone]);

  React.useEffect(() => {
    if (schemaField && schemaField.type !== fieldType) {
      setFieldType(schemaField.type);
    }
  }, [fieldType]);

  const ref = React.useRef<SchemaFormRef>(null);
  const isEditForm = !!fieldKey && !isClone;

  const handleSubmit = async (data: ListOfValuesField) => {
    const formData = {
      ...data,
      manualEntry: schema.schemaType === "manual-entry" ? { ...data.manualEntry } : null
    };

    if (formData.manualEntry) {
      delete formData.manualEntry.tagType;
    }

    const { placeholder } = formData;

    if (!Array.isArray(placeholder) || placeholder.length === 0 || !placeholder[0].value) {
      formData.placeholder = [];
    }

    try {
      if (isEditForm && !isClone) {
        await updateField({ idOrKey, previousField: schemaField, updates: formData });
      } else {
        await createSchemaField({
          field: formData,
          idOrKey
        });
      }

      enqueueSnackbar(`Field ${isEditForm ? "update" : "added"} successfully.`, {
        variant: "success"
      });

      onClose();
      navigate(`/schemas/${idOrKey}`);
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas/${idOrKey}/fields`);
  };

  const handleSave = async () => {
    ref.current && ref.current.onSubmit();
  };

  const loading = loadingSchema || creatingField || updatingField;

  const renderFieldTypeButton = (
    fieldType: ListOfValuesFieldType,
    label: string,
    icon: React.ReactNode
  ) => {
    return (
      <Box
        onClick={() => setFieldType(fieldType)}
        sx={{
          bgcolor: "primary.light",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          my: 2,
          height: 50,
          borderRadius: 1,
          px: 2,
          "&:hover": {
            bgcolor: "primary.main",
            color: "white",
            cursor: "pointer"
          }
        }}
      >
        <Box sx={{ "> *": { mt: 0.5 } }}>{icon}</Box>
        <Typography variant="button" sx={{ fontWeight: "normal", ml: 2, textTransform: "none" }}>
          <b>{label}</b>
        </Typography>
      </Box>
    );
  };

  return (
    <Drawer
      title={`${isEditForm ? "Edit" : "Add"} Field`}
      onClose={onClose}
      width={795}
      loading={loading}
      actions={[
        ...(fieldType
          ? [
              {
                text: isEditForm ? "Save" : "Create",
                action: handleSave,
                disabled: loading
              }
            ]
          : [{}]),
        { text: "Cancel", action: onClose }
      ]}
    >
      {fieldType ? (
        <Box>
          <WithLoader loading={loading}>
            <SchemaFieldForm
              isEditForm={isEditForm}
              field={schemaField || ({ type: fieldType } as Partial<ListOfValuesField>)}
              onSubmit={handleSubmit}
              ref={ref}
              isClone={isClone}
            />
          </WithLoader>
        </Box>
      ) : (
        <Box>
          {renderFieldTypeButton("string", "String", <ApplicationIcon name="type" />)}
          {renderFieldTypeButton("string[]", "String Array", <ApplicationIcon name="type" />)}
          {renderFieldTypeButton("markdown", "Markdown", <ApplicationIcon name="type" />)}
          {renderFieldTypeButton("markdown[]", "Markdown Array", <ApplicationIcon name="type" />)}
          {renderFieldTypeButton("number", "Number", <ApplicationIcon name="hash" />)}
          {/* {renderFieldTypeButton("select", "Select", <ApplicationIcon name="text-select" />)} */}
          {/* {renderFieldTypeButton("boolean", "Boolean", <ApplicationIcon name="toggle-left" />)} */}
        </Box>
      )}
    </Drawer>
  );
};

export default AddEditField;
