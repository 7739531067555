import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesApi } from "../../../apis";
import { useGetSchemaByIdOrKey } from "./useGetSchemaByIdOrKey";
import { LayoutItem, ListOfValuesSchemaDocumentResponse } from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

interface Variables {
  itemPathKey: string;
  action: "replace" | "add" | "remove";
  value: Partial<LayoutItem>;
}

export const useUpdateSchemaGridLayout = (schemaId: string) => {
  const queryClient = useQueryClient();
  const { schema: currentSchema } = useGetSchemaByIdOrKey(schemaId);

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    ({ itemPathKey, action, value }: Variables) =>
      ListOfValuesApi.Schemas.updateSchemaGridLayout(
        schemaId,
        itemPathKey,
        action,
        value,
        currentSchema.layout.grid
      ),
    {
      onSuccess: (schema) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(schemaId),
          () => schema
        );
      }
    }
  );

  return {
    status: data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateGridLayout: mutateAsync
  };
};
