import { useMutation } from "react-query";
import { CreateListOfValuesSchemaBody } from "../../../types";
import { ListOfValuesApi } from "../../..";

export const useCreateSchema = () => {
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (schema: CreateListOfValuesSchemaBody) => ListOfValuesApi.Schemas.create(schema)
  );

  return {
    schema: data,
    createSchema: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
