import * as React from "react";
import { Box, Button } from "@mui/material";
import { useGetSchemaByIdOrKey, useGetSchemaPermissions } from "@packages/service-api";
import SchemaFieldsGrid from "./SchemaFieldsGrid";
import { ApplicationIcon, WithLoader } from "@packages/theme-mui-v5";
import { useInternalNavigate } from "../../../../../components/InternalNavigationProvider";

type Props = {
  schemaIdOrKey: string;
};

const SchemaFieldsTab: React.FC<Props> = (props) => {
  const { schemaIdOrKey } = props;
  const navigate = useInternalNavigate();
  const { schema, loading } = useGetSchemaByIdOrKey(schemaIdOrKey);

  const { configure } = useGetSchemaPermissions(schema);

  return (
    <Box sx={{ minHeight: 300 }}>
      <WithLoader loading={loading}>
        {configure && (
          <Box sx={{ my: 2, px: 2, justifyContent: "flex-end", display: "flex" }}>
            <Button
              variant="contained"
              size="small"
              startIcon={<ApplicationIcon name="plus" />}
              onClick={() => navigate(`/schemas/${schema?.schemaId}/fields/new`)}
            >
              Add Field
            </Button>
          </Box>
        )}

        {schema && (
          <SchemaFieldsGrid
            configure={configure}
            schemaId={schema.schemaId}
            fields={schema.fields}
          />
        )}
      </WithLoader>
    </Box>
  );
};

export default SchemaFieldsTab;
