import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";

import {
  ListOfValuesItem,
  useCreateSchemaItem,
  useGetSchemaByIdOrKey,
  useGetSchemaItemById,
  useUpdateSchemaItem
} from "@packages/service-api";
import { useInternalNavigate } from "../components/InternalNavigationProvider";
import DynamicForm, { DynamicFormApi } from "../components/items/dynamic-form/DynamicForm";
import { useCallback } from "react";

const AddEditItem = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { itemId, idOrKey } = useParams<{ itemId: string; idOrKey: string }>();

  const { schema, loading: schemaLoading } = useGetSchemaByIdOrKey(idOrKey);
  const { item, loading: itemLoading } = useGetSchemaItemById(idOrKey, itemId);

  const { createItem, loading: creating } = useCreateSchemaItem(idOrKey);
  const { updateItem, loading: updating } = useUpdateSchemaItem(idOrKey, itemId);

  const ref = React.useRef<{ form: DynamicFormApi }>(undefined);

  const isEdit = !!itemId;
  const loading = schemaLoading || creating || itemLoading || updating;

  const handleSave = useCallback(async () => {
    if (ref.current) {
      ref.current.form.handleSubmit();
    }
  }, [ref]);

  const onClose = useCallback(() => {
    navigate(`/schemas/${idOrKey}/items`);
  }, [navigate, idOrKey]);

  const handleSubmit = useCallback(
    async (nextAttributes: ListOfValuesItem["attributes"]) => {
      try {
        if (isEdit) {
          await updateItem({ nextAttributes, schema });
        } else {
          await createItem(nextAttributes);
        }

        enqueueSnackbar(`Item ${isEdit ? "updated" : "created"} successfully.`, {
          variant: "success"
        });
        onClose();
      } catch (error) {
        enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
          variant: "error"
        });
      }
    },
    [createItem, updateItem, enqueueSnackbar, onClose, isEdit]
  );

  return (
    <Drawer
      title={`${isEdit ? "Edit" : "Add"} Item`}
      onClose={onClose}
      loading={loading}
      disableBackdropClick
      actions={[
        { text: `${isEdit ? "Update" : "Add"}`, action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading}>
        {!loading && schema && (
          <DynamicForm schema={schema} item={item} onSubmit={handleSubmit} ref={ref} />
        )}
      </WithLoader>
    </Drawer>
  );
};

export default AddEditItem;
