import { useMutation, useQueryClient } from "react-query";

import { ListOfValuesApi } from "../../..";
import { ListOfValuesItemListResponse } from "../../../types";
import { schemaItemsQueryKey } from "../query-cache";

export const useDeleteSchemaItem = (idOrKey: string, itemId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    () => ListOfValuesApi.Items.delete(idOrKey, itemId),
    {
      onSuccess: () => {
        queryClient.setQueryData<ListOfValuesItemListResponse | undefined>(
          schemaItemsQueryKey(idOrKey),
          (currentState: ListOfValuesItemListResponse | undefined) => {
            if (!currentState) return undefined;

            return {
              ...currentState,
              data: currentState.data.filter((f) => f.itemId !== itemId)
            };
          }
        );
      }
    }
  );

  return {
    deleteItem: mutateAsync,
    isError,
    isSuccess,
    loading: isLoading
  };
};
