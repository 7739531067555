import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import { SchemaFormRef } from "../components/schemas/SchemaForm";

import {
  LayoutItem,
  useGetSchemaByIdOrKey,
  GridItemType,
  useUpdateSchemaGridLayout
} from "@packages/service-api";
import { Box, Typography } from "@mui/material";
import SchemaLayoutForm from "../components/schemas/SchemaLayoutForm";
import { findFieldByPath } from "@packages/utils";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const AddEditLayoutGridItem = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [itemType, setItemType] = React.useState<GridItemType>();

  const { action, itemPathKey, idOrKey } = useParams<{
    action: "new" | "edit";
    itemPathKey: string;
    idOrKey: string;
  }>();

  const { updateGridLayout, loading: loadingSchemaGridLayout } = useUpdateSchemaGridLayout(idOrKey);
  const { schema, loading: loadingSchema } = useGetSchemaByIdOrKey(idOrKey);

  const layoutItem = React.useMemo(() => {
    return action === "edit" && findFieldByPath(itemPathKey, schema?.layout?.grid);
  }, [schema, itemPathKey]);

  React.useEffect(() => {
    if (layoutItem && layoutItem.type !== itemType) {
      setItemType(layoutItem.type);
    }
  }, [layoutItem, itemType]);

  const ref = React.useRef<SchemaFormRef>(null);
  const isEditForm = action === "edit";

  const handleSubmit = async (formData: Partial<LayoutItem>) => {
    try {
      await updateGridLayout({
        itemPathKey,
        action: isEditForm ? "replace" : "add",
        value: {
          ...layoutItem,
          ...formData
        } as LayoutItem
      });
      enqueueSnackbar(`Layout ${isEditForm ? "updated" : "added"} successfully.`, {
        variant: "success"
      });
      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate(`/schemas/${idOrKey}/layout`);
  };

  const loading = loadingSchemaGridLayout || loadingSchema;

  const renderFieldTypeButton = (fieldType: GridItemType, label: string) => {
    return (
      <Box
        onClick={() => setItemType(fieldType)}
        sx={{
          bgcolor: "primary.light",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          my: 2,
          height: 50,
          borderRadius: 1,
          px: 2,
          "&:hover": {
            bgcolor: "primary.main",
            color: "white",
            cursor: "pointer"
          }
        }}
      >
        <Typography variant="button" sx={{ fontWeight: "normal", ml: 2, textTransform: "none" }}>
          <b>{label}</b>
        </Typography>
      </Box>
    );
  };

  const handleSave = async () => {
    ref.current && ref.current.onSubmit();
  };

  const renderFieldForm = (type: LayoutItem["type"]) => {
    return (
      <SchemaLayoutForm
        item={layoutItem || ({ type } as Partial<LayoutItem>)}
        onSubmit={handleSubmit}
        ref={ref}
      />
    );
  };

  return (
    <Drawer
      title={`${isEditForm ? "Edit" : "Add"} Layout Item`}
      onClose={onClose}
      loading={loading}
      actions={[
        { text: "Save", action: handleSave },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loadingSchemaGridLayout}>
        {itemType ? (
          <Box>{renderFieldForm(itemType)}</Box>
        ) : (
          <Box>
            {renderFieldTypeButton("group", "Group")}
            {renderFieldTypeButton("field", "Field")}
          </Box>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default AddEditLayoutGridItem;
