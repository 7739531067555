import React from "react";
import { Box, IconButton, Input, Tooltip } from "@mui/material";

import { LayoutGroup } from "@packages/service-api";
import { ApplicationIcon, ApplicationIconName } from "@packages/theme-mui-v5";

type Props = {
  group: LayoutGroup;
  onUpdateGroup: (layoutId: string, name: string) => void;
};

const GroupName: React.FC<Props> = (props) => {
  const { group, onUpdateGroup } = props;

  const [editActive, setEditActive] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<string>(group.name);

  const handleSave = () => {
    onUpdateGroup("name", inputValue);
    setEditActive(false);
  };

  const handleChangeDirection = () => {
    onUpdateGroup("direction", group.direction === "horizontal" ? "vertical" : "horizontal");
  };

  const renderIconButton = (
    icon: ApplicationIconName,
    color: string,
    onClick: () => void,
    tooltip: string
  ) => (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={onClick}
        sx={{
          color: `${color}.main`,
          p: 0,
          m: 0,
          mx: 0.3,
          mt: -0.2,
          "&:hover": { backgroundColor: "unset", color: `${color}.dark` }
        }}
      >
        <ApplicationIcon name={icon} />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        color: !group.name ? "grey.500" : undefined,
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        fontWeight: group.name ? "bolder" : undefined,
        height: 20,
        left: 10,
        pl: 1,
        position: "absolute",
        pr: 0.4,
        top: -10
      }}
    >
      <Box sx={{ mr: 1 }}>
        {editActive ? (
          <Input
            placeholder="Group Name"
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ borderBottom: "none", fontSize: 12, height: 20 }}
            value={inputValue}
          />
        ) : (
          <>{group.name || "Group Name"}</>
        )}
      </Box>
      <Box>
        {editActive ? (
          <>
            {renderIconButton(
              "cancel",
              "error",
              () => {
                setEditActive(false);
                setInputValue(group.name);
              },
              "Cancel"
            )}
            {renderIconButton("check", "primary", () => handleSave(), "Save")}
          </>
        ) : (
          <>
            {renderIconButton(
              "pencil",
              "secondary",
              () => {
                setEditActive(true);
              },
              "Edit Name"
            )}
            {renderIconButton(
              group.direction === "vertical" ? "chevron-down" : "chevron-right",
              "secondary",
              handleChangeDirection,
              `Render fields ${group.direction === "horizontal" ? "horizontally" : "vertically"}`
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default GroupName;
