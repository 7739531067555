import { FC } from "react";
import { RenderSchema } from "../../../../../RenderSchema";

type Props = {
  schemaIdOrKey: string;
};

export const ItemsTab: FC<Props> = (props) => {
  const { schemaIdOrKey } = props;
  return (
    <RenderSchema
      hideToolbar
      schemaIdOrKey={schemaIdOrKey}
      defaultViewMode={"grid"}
      supportedViewModes={["grid", "form"]}
    />
  );
};
