import { FC, useMemo, useRef } from "react";
import { ListOfValuesSchema } from "@packages/service-api";
import {
  ColDef,
  DataGrid,
  GetDataPath,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  RowActionsCell
} from "@packages/data-grid";

import { flattenGrid } from "@packages/utils";
import { useInternalNavigate } from "../../../../../../components/InternalNavigationProvider";
type Props = {
  schema: ListOfValuesSchema;
  configure: boolean;
};

const SchemaLayoutGrid: FC<Props> = (props) => {
  const { schema, configure } = props;

  const gridRef = useRef(null);
  const navigate = useInternalNavigate();

  const rowData = useMemo(() => {
    if (!schema) {
      return [];
    }

    return [...flattenGrid(schema?.layout?.grid || []), { type: "root", layoutPath: ["root"] }];
  }, [schema]);

  const RenderActionsCell = (params: ICellRendererParams) => {
    const actions = [];
    const path = params.data.layoutPath.join(":");
    if (params.data.type === "group" || params.data.type === "root") {
      actions.push({
        icon: "list-plus",
        color: "primary",
        tooltip: "Add item",
        action: () => {
          if (params.data.layoutPath === "root") {
            navigate(`/schemas/${schema?.schemaId}/layout/grid/root/add`);
          } else {
            navigate(`/schemas/${schema?.schemaId}/layout/grid/${path}/add`);
          }
        }
      });
    }

    if (params.data.type !== "root") {
      if (params.data.type !== "field") {
        actions.push({
          icon: "edit",
          color: "primary",
          tooltip: "Edit",
          action: () => navigate(`/schemas/${schema?.schemaId}/layout/grid/${path}/edit`)
        });
      }
      actions.push({
        icon: "delete",
        color: "error",
        tooltip: "Delete",
        action: () => navigate(`/schemas/${schema?.schemaId}/layout/grid/${path}/delete`)
      });
    }

    return <RowActionsCell actions={actions} />;
  };

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "Field Key",
        field: "key",
        minWidth: 100
      },
      ...(configure
        ? [
            {
              headerName: "Actions",
              maxWidth: 150,
              minWidth: 150,
              cellRenderer: RenderActionsCell
            }
          ]
        : [])
    ];
  }, [configure]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: false,
      sortable: false
    };
  }, []);

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: "Name",
      valueGetter: (params) => {
        if (params.data.type === "group") {
          return params.data.name;
        } else if (params.data.type === "field") {
          const field = schema?.fields.find((field) => field.fieldKey === params.data.key);
          return field?.label[0]?.value || "";
        }
        return "";
      },
      minWidth: 150,
      cellRendererParams: {
        suppressCount: false
      }
    };
  }, [schema]);

  const getDataPath = useMemo<GetDataPath>(() => {
    return (data) => {
      return data.layoutPath;
    };
  }, []);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => {
      return params.data.layoutId;
    };
  }, []);

  const gridOptions = useMemo(() => {
    return {
      masterDetail: true,
      detailRowHeight: 60,
      autoSizeAllColumns: true,
      rowGroup: true
    };
  }, []);

  return (
    <DataGrid
      gridRef={gridRef}
      rowData={rowData}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      autoGroupColumnDef={autoGroupColumnDef}
      gridOptions={gridOptions}
      treeData={true}
      getDataPath={getDataPath}
      getRowId={getRowId}
    />
  );
};

export default SchemaLayoutGrid;
