import React from "react";
import {
  DndContext,
  DndContextProps,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import SortableListItem from "./SortableListItem";

type Props<T, P> = DndContextProps & {
  listItemComponent: React.FC<P>;
  listItemComponentProps?: Partial<P>;
  direction?: "vertical" | "horizontal";
  fullWidth?: boolean;
  items: T[];
  itemKey: string;
  onSortChanged: (items: T[]) => void;
};

export function DragAndDrop<T = unknown, P = unknown>(props: Props<T, P>) {
  const {
    direction = "vertical",
    fullWidth,
    items,
    itemKey,
    listItemComponent,
    listItemComponentProps = {},
    onSortChanged,
    ...restProps
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item[itemKey] === active.id);
      const newIndex = items.findIndex((item) => item[itemKey] === over.id);

      onSortChanged(arrayMove<T>([...items], oldIndex, newIndex));
    }
  };

  const ListItemComponent = listItemComponent;

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      {...restProps}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map((item) => item[itemKey])}
        strategy={
          direction === "vertical" ? verticalListSortingStrategy : horizontalListSortingStrategy
        }
      >
        {items.map((item, index) => {
          const componentProps: P = {
            ...(listItemComponentProps as P),
            item,
            index
          };
          return (
            <SortableListItem key={item[itemKey]} id={item[itemKey]} fullWidth={fullWidth}>
              <ListItemComponent {...componentProps} />
            </SortableListItem>
          );
        })}
      </SortableContext>
    </DndContext>
  );
}
