import * as React from "react";
import { useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import {
  useCreateSchema,
  CreateListOfValuesSchemaBody,
  useUpdateSchema,
  useGetSchemaByIdOrKey,
  Location
} from "@packages/service-api";
import SchemaForm, { SchemaFormRef } from "../components/schemas/SchemaForm";
import { schemasGridRef } from "../pages/schemas/SchemasList/SchemasGrid";
import { useInternalNavigate } from "../components/InternalNavigationProvider";

const AddEditSchema = () => {
  const navigate = useInternalNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { idOrKey } = useParams<{ idOrKey: string }>();

  const { createSchema, loading } = useCreateSchema();

  const { schema: currentSchema, loading: loadingCurrentSchema } = useGetSchemaByIdOrKey(idOrKey);
  const { updateSchema, loading: updating } = useUpdateSchema();

  const ref = React.useRef<SchemaFormRef>(null);
  const isEditForm = !!idOrKey;

  type FormFields = Partial<CreateListOfValuesSchemaBody> & {
    location?: Location;
    locationType?: "all" | "single";
    siteId?: string;
    timeRange?: string | number;
  };

  const handleSubmit = async (formData: FormFields) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { location, locationType, siteId, timeRange, ...restData } = formData;

      const formSubmissionData: Partial<CreateListOfValuesSchemaBody> = {
        ...currentSchema,
        ...restData,
        locationId: location?.locationId || null
      };

      if (isEditForm) {
        await updateSchema({ idOrKey, previousSchema: currentSchema, updates: formSubmissionData });
      } else {
        const schema = await createSchema(formSubmissionData as CreateListOfValuesSchemaBody);
        navigate(`/schemas/${schema.data.schemaId}`);
      }
      enqueueSnackbar(`Schema ${isEditForm ? "update" : "added"} successfully.`, {
        variant: "success"
      });
      schemasGridRef?.current?.api?.refreshServerSide();
      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const onClose = () => {
    navigate("/schemas");
  };

  const handleSave = async () => {
    ref.current && ref.current.onSubmit();
  };

  return (
    <Drawer
      title={`${isEditForm ? "Edit" : "Add"} Schema`}
      contentTitle="Schema"
      onClose={onClose}
      loading={loading || loadingCurrentSchema}
      actions={[
        {
          text: isEditForm ? "Save" : "Create",
          action: handleSave,
          disabled: loading
        },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading || loadingCurrentSchema || updating}>
        <SchemaForm
          onSubmit={handleSubmit}
          schema={isEditForm ? currentSchema : undefined}
          ref={ref}
          isEditForm={isEditForm}
        />
      </WithLoader>
    </Drawer>
  );
};

export default AddEditSchema;
