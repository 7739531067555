import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesApi } from "../../..";
import { ListOfValuesSchemaDocumentResponse } from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

type Variables = {
  fieldKey: string;
  idOrKey: string;
};

export const useDeleteSchemaField = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ idOrKey, fieldKey }: Variables) =>
      ListOfValuesApi.Schemas.deleteSchemaField(idOrKey, fieldKey),
    {
      onSuccess: (_application, variables) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(variables.idOrKey),
          (currentState: ListOfValuesSchemaDocumentResponse | undefined) => {
            if (!currentState) return undefined;

            return {
              ...currentState,
              data: {
                ...currentState.data,
                fields: currentState.data.fields.filter((f) => f.fieldKey !== variables.fieldKey)
              }
            };
          }
        );
      }
    }
  );

  return {
    loading: isLoading,
    isError,
    isSuccess,
    deleteField: mutateAsync
  };
};
