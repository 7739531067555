import { Box, Button, TextField } from "@mui/material";
import { LayoutField, ListOfValuesField } from "@packages/service-api";
import { ApplicationIcon } from "@packages/theme-mui-v5";
import React from "react";

export type SchemaLayoutRowFieldProps = {
  fields: ListOfValuesField[];
  item: LayoutField;
  onDeleteField: (layoutId: string) => void;
};

const SchemaLayoutRowField: React.FC<SchemaLayoutRowFieldProps> = (props) => {
  const { fields, item: layoutItem, onDeleteField } = props;
  const [hoverActive, setHoverActive] = React.useState<boolean>(false);

  const field = React.useMemo(() => {
    return fields.find((field) => field?.fieldKey === layoutItem.key);
  }, [fields, layoutItem]);

  return (
    <Box
      key={layoutItem.layoutId}
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
      sx={{ flex: 1, maxWidth: 300, mx: 1 }}
    >
      {field ? (
        <TextField
          label={field.label[0]?.value || "No Label"}
          InputProps={{
            startAdornment: (
              <ApplicationIcon name={field.type === "number" ? "file-digit" : "file-text"} />
            )
          }}
          fullWidth
          required={field.isRequired}
          sx={{ mb: 0 }}
          value=""
          variant="outlined"
        />
      ) : (
        <TextField
          label="Unknown field"
          fullWidth
          value={layoutItem.key}
          variant="outlined"
          sx={{ mb: 0 }}
          InputProps={{ readOnly: true }}
        />
      )}

      <Box
        sx={{
          height: 20,
          "& > *": {
            mx: 1
          }
        }}
      >
        {hoverActive && (
          <>
            {/* TODO: Implement the ability to edit directly from the layout */}
            {/* <Button
              size="small"
              variant="text"
              sx={{
                color: "grey.300",
                mt: 0.2,
                mb: 0,
                "&:hover": { background: "none", color: "grey.500" }
              }}
            >
              Edit
            </Button>*/}
            <Button
              color="error"
              onClick={() => onDeleteField(layoutItem.layoutId)}
              size="small"
              sx={{
                color: "error.main",
                mt: 0.2,
                mb: 0,
                "&:hover": { background: "none", color: "error.dark" }
              }}
              variant="text"
            >
              Delete
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SchemaLayoutRowField;
