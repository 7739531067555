import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesApi } from "../../..";
import {
  ListOfValuesSchemaDocumentResponse,
  CreateListOfValuesSchemaBody,
  ListOfValuesSchema
} from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

type Variables = {
  idOrKey: string;
  previousSchema: ListOfValuesSchema;
  updates: Partial<CreateListOfValuesSchemaBody>;
};

export const useUpdateSchema = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ idOrKey, previousSchema, updates }: Variables) =>
      ListOfValuesApi.Schemas.update(idOrKey, previousSchema, updates),
    {
      onSuccess: (schema, variables) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(variables.idOrKey),
          () => schema
        );
      }
    }
  );

  return {
    loading: isLoading,
    isError,
    isSuccess,
    updateSchema: mutateAsync
  };
};
