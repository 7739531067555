import { useMutation, useQueryClient } from "react-query";
import { ListOfValuesApi } from "../../../apis";
import { useGetSchemaByIdOrKey } from "./useGetSchemaByIdOrKey";
import { Layout, ListOfValuesSchemaDocumentResponse } from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

export const useUpdateSchemaFormLayout = (schemaId: string) => {
  const queryClient = useQueryClient();
  const { schema: currentSchema } = useGetSchemaByIdOrKey(schemaId);

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    (layout: Layout) =>
      ListOfValuesApi.Schemas.updateSchemaFormLayout(schemaId, currentSchema.layout.form, layout),
    {
      onSuccess: (schema) => {
        queryClient.setQueryData<ListOfValuesSchemaDocumentResponse | undefined>(
          schemasSingleQueryKey(schemaId),
          () => schema
        );
      }
    }
  );

  return {
    status: data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateFormLayout: mutateAsync
  };
};
