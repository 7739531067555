import { useQuery } from "react-query";
import { ListOfValuesApi } from "../../..";
import { ListOfValuesItemDocumentResponse } from "../../../types";
import { singleSchemaItemQueryKey } from "../query-cache";

export const useGetSchemaItemById = (idOrKey: string, itemId: string) => {
  const { data, error, isLoading, isFetching } = useQuery<ListOfValuesItemDocumentResponse, Error>({
    queryKey: singleSchemaItemQueryKey(idOrKey, itemId),
    enabled: !!idOrKey && !!itemId,
    queryFn: () => ListOfValuesApi.Items.getByItemId(idOrKey, itemId)
  });

  return {
    item: data?.data,
    error: error,
    loading: isLoading || isFetching
  };
};
